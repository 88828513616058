<template>
    <div class="reviews">
        <v-row style="max-width: 1800px" class="mx-auto">
            <v-col cols="12">
                <v-card class="mx-auto elevation-6">
                    <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            {{ $t('reviews.list_of_reviews', { p1: reviews.length }) }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn-toggle v-model="calendar" tile group mandatory dense>
                            <v-btn value="left" @click="onClickToday"> <v-icon>mdi-calendar</v-icon> {{ $t('reviews.buttons.today') }} </v-btn>
                            <v-btn value="center" @click="onClickYesterday"> <v-icon>mdi-calendar-arrow-left</v-icon> {{ $t('reviews.buttons.yesterday') }} </v-btn>
                            <v-btn value="right" @click="onClickMonth"> <v-icon>mdi-calendar-month</v-icon> {{ $t('reviews.buttons.month') }} </v-btn>
                            <v-menu v-model="menu_calendar" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-btn text class="menu-button" v-on="on">
                                        <v-icon class="mr-1">mdi-calendar-multiple</v-icon>
                                        {{ $t('reviews.buttons.range') }}
                                    </v-btn>
                                </template>
                                <div @keydown.esc="closeCalendar" tabindex="0">
                                    <v-date-picker 
                                        range 
                                        v-model="dates" 
                                        locale="ru-ru" 
                                        selected-items-text="Выбран период" 
                                        :first-day-of-week="1" 
                                        @change="onClickChangeDate"
                                    >
                                    </v-date-picker>
                                </div>
                            </v-menu>
                        </v-btn-toggle>

                        <v-spacer></v-spacer>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span class="tooltip-text" v-bind="attrs" v-on="on">
                                    <v-btn @click="onClickDownloadData" class="mx-4 px-2" tile icon rounded>
                                        <v-img src="../../assets/images/excel-300.png" width="40"></v-img>
                                    </v-btn>
                                </span>
                            </template>
                            <span>Выгрузка данных в таблицу</span>
                        </v-tooltip>

                        <v-btn icon @click="onClickUpdate">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-title class="pt-0 pb-2 d-flex">
                        <v-row dense justify="end">
                            <v-col cols="3">
                                <v-select v-if="!userIsPoint" class="mx-2 flex-grow-0" style="width: 220px;" no-data-text="Нет данных" @change="updateData" :items="filter_points" name="point" item-text="name" item-value="id" v-model="filter_point" label="По точке" hide-details></v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-select class="mx-2 flex-grow-0" style="width: 220px;" no-data-text="Нет данных" @change="updateData" :items="filter_couriers" name="courier" item-text="name" item-value="tid" v-model="filter_courier" label="По курьеру" hide-details></v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-select class="mx-2 flex-grow-0" style="width: 220px;" no-data-text="Нет данных" :items="quick_filters" v-model="quick_filter" label="Быстрые фильтры" @change="filters()" hide-details ></v-select>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="search" class="mx-2 flex-grow-0" style="width: 220px;" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details @change="filters()"></v-text-field>
                            </v-col>
                            <v-col cols="8" v-if="reviews.length">
                                <div class="d-flex pt-3">
                                    <p class="mb-0">Средняя оценка: </p>
                                    <v-rating
                                        background-color="grey lighten-1"
                                        color="warning"
                                        empty-icon="mdi-star-outline"
                                        full-icon="mdi-star"
                                        half-icon="mdi-star-half-full"
                                        length="5"
                                        size="28"
                                        :value="reviewsStars"
                                        readonly
                                        dense
                                        half-increments
                                    ></v-rating>
                                    <p class="mb-0" style="margin: 2px 0;">{{ Math.floor(reviewsStars * 100) / 100 }}</p>
                                </div>
                            </v-col>
                            <v-col cols="3" align-self="end">
                                <v-btn 
                                    plain text small :color="tabularView ? 'indigo' : 'gray'" right style="margin: 5px 0 0 0;height: 44px;"
                                    @click="clickTabularView()"
                                >Табличный вид</v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-chip-group 
                                    multiple 
                                    active-class="indigo--text"
                                    column
                                    >
                                    <v-chip
                                    v-for="tag in hashtags" 
                                    :key="tag.name"
                                    :input-value="tag.isSelected"
                                    @click="filters(tag)"
                                    small
                                    outlined
                                    >
                                        {{tag.name}}
                                    </v-chip>
                                </v-chip-group>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-card v-show="tabularView">
                        <v-data-table
                            :mobile-breakpoint="0"
                            dense
                            no-data-text="Нет данных"
                            no-results-text="Нет данных, попробуйте изменить запрос"
                            :footer-props="{
                                pageText: '{0} - {1} из {2}',
                                itemsPerPageText: 'Показывать на странице',
                                itemsPerPageAllText: 'все',
                                showFirstLastPage: true,
                                itemsPerPageOptions: [25, 50, 100, -1],
                            }"
                            :headers="headers"
                            :search="search"
                            :items="reviews"
                            item-key="index"
                            item-class="rowItem"
                            :loading="loading"
                            :loading-text="$t('common.loading')"
                            @click:row="onClickReview($event)"
                        >

                            <template v-slot:[`item.name`]="{ item }">
                                <b>{{ item.name }}</b>
                            </template>

                            <template v-slot:[`item.guest`]="{ item }">
                                <span style="min-width: 180px">
                                    <v-menu  bottom color="rgba(255,255,255, 0)" open-on-hover :close-on-content-click="false" offset-y close-delay="100" open-delay="200" >
                                        <template v-slot:activator="{ on, attrs }">
                                            <td v-bind="attrs" v-on="on">
                                                <router-link v-if="item.guest_phone" :to="{ name: 'guests_phone', params: {phone: item.guest_phone} }" class="text-decoration-none" style="color:inherit">
                                                    <span class="py-2 tooltip-text" :class="loading ? '' : (item.guest_phone ? 'guest-selectable' : 'guest-error')" @click="onClickGuest(item)">{{ getGuestPhoneName(item) }}</span>
                                                </router-link>
                                                <span v-else class="py-2 tooltip-text">{{ getGuestStr(item.guest) }}</span>
                                            </td>
                                        </template>
                                        <v-card class="pa-2 menu_text" max-width="800px" v-if="item.guest_item"> 
                                            <div>Телефон: <b v-if="item.guest_phone !== null">{{ item.guest_phone }}</b> <b v-else>Номер не указан</b></div>
                                            <div>Имя: <b v-if="item.guest_name !== null">{{ item.guest_name }}</b> <b v-else>Имя не указано</b></div>
                                            <div>Первый визит: <b>{{ timeToString(item.guest_item.date_create) }}</b></div>
                                            <div>Последний визит: <b>{{ timeToString(item.guest_item.date_visit) }}</b></div>
                                            <v-divider class="mt-1 mb-1"></v-divider>
                                            <div v-if="($store.state.userIsPoint && $store.state.user.point_id) || filter_point">
                                                <div >Заказы точки:     <b>{{  item.guest_item.count }}</b></div>
                                                <div>Чек у точки:   <b>{{  item.guest_item.cost }}</b></div>
                                                <div>Заказы у других: <b>{{ item.guest_item.other_count }}</b></div>
                                                <div>Чек у других: <b>{{ item.guest_item.other_cost }} </b></div>
                                            </div>
                                            <div>Заказы всего: <b>{{ item.guest_item.order_count }}</b></div>
                                            <div v-if="!(($store.state.userIsPoint && $store.state.user.point_id) || filter_point)" >Чек всего: <b>{{ item.guest_item.pay_cost }} </b></div>
                                            <div v-if="!$store.state.userIsPoint">Точки: <b>{{ item.guest_item.points }}</b></div>
                                        </v-card>
                                    </v-menu> 
                                </span>
                            </template>

                            <template v-slot:[`item.value`]="{ item }">
                                <v-rating
                                    background-color="grey lighten-1"
                                    color="warning"
                                    empty-icon="mdi-star-outline"
                                    full-icon="mdi-star"
                                    half-icon="mdi-star-half-full"
                                    length="5"
                                    size="14"
                                    :value="item.value"
                                    dense
                                    readonly
                                ></v-rating>
                            </template>

                            <template v-slot:[`item.review_text`]="{ item }">
                                <v-menu 
                                    bottom 
                                    color="rgba(255,255,255, 0)"
                                    open-on-hover
                                    :close-on-content-click="false" 
                                    offset-y
                                    close-delay="100"
                                    open-delay="200"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <span 
                                            class="tooltip-text" 
                                            v-bind="attrs" 
                                            v-on="on"
                                        >
                                            {{ item.review_text && item.review_text.length > 24 ?  item.review_text.substr(0, 24) + '...' : item.review_text }}
                                        </span>
                                    </template>
                                    <v-card class="pa-2 menu_text" max-width="800px"> 
                                        <div>
                                            Гость: <b v-if="item.guest_name !== null">{{ item.guest_name }}</b> <b v-else>Имя не указано</b>
                                        </div>
                                        <div>
                                            Номер гостя: <b v-if="item.guest_phone !== null">{{ item.guest_phone }}</b> <b v-else>Номер не указан</b>
                                        </div>
                                        <v-rating
                                            background-color="grey lighten-1"
                                            color="warning"
                                            empty-icon="mdi-star-outline"
                                            full-icon="mdi-star"
                                            half-icon="mdi-star-half-full"
                                            length="5"
                                            size="15"
                                            :value="item.value"
                                            dense
                                            readonly
                                        >
                                        </v-rating>
                                        <div>
                                            Отзыв: <b>{{ item.review_text }}</b>
                                        </div>
                                        <v-divider class="mt-1 mb-1"></v-divider>
                                        <div>
                                            Точка: <b>{{ pointNameGet(item.point_id) }}</b>
                                        </div>
                                        <div>
                                            Курьер: <b>{{ item.courier_name }}</b>
                                        </div>
                                        <div>
                                            <span class="mr-2">Время доставки: <b>{{ getStringToDate(item.date_complete, 'DD MMM HH:mm')  }}</b></span>
                                            <v-chip 
                                                class="px-1" 
                                                :color="timeColorHtml(item.date_complete, item.date_delivery)" 
                                                x-small 
                                                label 
                                                text-color="white"
                                            >
                                                {{ timeError(new Date(item.date_complete), new Date(item.date_delivery)) }}
                                            </v-chip>
                                        </div>
                                    </v-card>
                                </v-menu> 
                            </template>

                            <template v-slot:[`item.point_name`]="{ item }">
                                <div class="text-no-wrap">
                                    <b>{{ pointNameGet(item.point_id).length > 20 ?  pointNameGet(item.point_id).substr(0, 20) + '...' : pointNameGet(item.point_id) }}</b>
                                </div>
                            </template>

                            <template v-slot:[`item.platform`]="{ item }">
                                <img
                                    v-if="item.platform || item.value < 5"
                                    class="mt-1"
                                    height="24"
                                    alt="platform"
                                    :src="iconPlatformSource(item.platform)"
                                >
                                <span v-if="!item.platform && item.value >= 5">
                                    Без перехода
                                </span>
                            </template>

                            <template v-slot:[`item.courier_name`]="{ item }">
                                <div class="text-no-wrap">
                                    <v-avatar size="24" class="mr-2" v-if="item.courier_name != '--'">
                                    <img
                                        alt="courier"
                                        :src="`${ config.api.delivery + 'user_avatar?tid=' + item.courier_tid }`"
                                    >
                                    </v-avatar>
                                    <b>{{ item.courier_name.length > 20 ?  item.courier_name.substr(0, 20) + '...' : item.courier_name }}</b>
                                </div>
                            </template>

                            <template v-slot:[`item.respond`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span class="tooltip-text" v-bind="attrs" v-on="on">
                                            <v-icon :style="{color: respondColorHtml(item)}">{{ respondIcon(item) }}</v-icon>
                                        </span>
                                    </template>
                                    <span>{{ respondTooltip(item) }}</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:[`item.date_vote`]="{ item }">
                                {{ timeToString(item.date_vote) }}
                            </template>

                            <template v-slot:[`item.date_complete`]="{ item }">
                                <span class="mr-2">{{ timeToString(item.date_complete) }}</span>
                                <v-chip v-if="item.date_complete" class="px-1" :color="timeColorHtml(item.date_complete, item.date_delivery)" x-small label text-color="white">
                                    {{ timeError(item.date_complete, item.date_delivery) }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.order_name`]="{ item }">
                                <v-menu  bottom color="rgba(255,255,255, 0)" open-on-hover :close-on-content-click="false" offset-y close-delay="100" open-delay="200" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <span 
                                            class="tooltip-text" 
                                            v-bind="attrs" 
                                            v-on="on"
                                        >
                                            {{ item.order_name }}
                                        </span>
                                    </template>
                                    <v-card class="pa-2 menu_text" max-width="800px"> 
                                        <div>
                                            Название: <b>{{ item.order_name }}    </b>
                                            <v-avatar size="20">
                                                <img alt="source" :src="iconSource(item)" />
                                            </v-avatar>
                                        </div>
                                        <div>Точка: <b>{{ pointNameGet(item.point_id) }}</b></div>
                                        <div>Курьер: <b>{{ item.courier_name }}</b></div>
                                        <v-divider class="mt-1 mb-1"></v-divider>
                                        <div>Забран: <b>{{ timeToString(item.date_take) }} </b>
                                            <v-chip v-if="item.date_take" class="px-1 mb-1" :color="timeColorHtml(item.date_take, item.date_ready)" x-small label text-color="white">
                                                {{ timeError(item.date_take, item.date_ready) }}
                                            </v-chip>
                                        </div>
                                        <div>Доставлен: <b>{{ timeToString(item.date_complete) }}</b>
                                            <v-chip v-if="item.date_complete" class="px-1 mb-1" :color="timeColorHtml(item.date_complete, item.date_delivery)" x-small label text-color="white">
                                                {{ timeError(item.date_complete, item.date_delivery) }}
                                            </v-chip>
                                        </div>
                                    </v-card>
                                </v-menu> 
                            </template>

                        </v-data-table>
                    </v-card>

                    <v-card v-show="!tabularView" elevation="0">
                        <v-row justify="center"  height="auto" max-height="600px" min-height="60px" width="100%">
                            <v-col cols="2" align-self="center" v-if="reviews.length===0 && !loading"><div>Нет данных</div></v-col>
                            <v-col 
                                v-for="item in 6" 
                                :key="item" 
                                v-show="loading"
                                cols="10">
                                <v-card  elevation="2">
                                    <v-skeleton-loader
                                    :loading="loading"
                                    type="list-item-avatar-three-line"
                                    ></v-skeleton-loader>
                                </v-card>
                            </v-col>
                            <v-col 
                                v-for="item in reviewsBeforeScroll"
                                v-show="!loading"
                                cols="10"
                                :key="item.id"
                                align-self="center">
                                <v-card  elevation="2">
                                    <v-card-text>
                                        <v-row dense>
                                            <v-col cols="2">
                                                <v-menu  bottom color="rgba(255,255,255, 0)" open-on-hover :close-on-content-click="false" offset-y close-delay="100" open-delay="200" >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <div v-bind="attrs" v-on="on">
                                                            <v-icon size="50px">mdi-account-circle</v-icon>
                                                            <div>
                                                                <b v-if="item.guest_name !== null">{{ item.guest_name }}</b> <b v-else>Имя не указано</b>
                                                            </div>
                                                            <div>
                                                                <b v-if="item.guest_phone !== null">{{ item.guest_phone }}</b> <b v-else>Номер не указан</b>
                                                            </div>
                                                            <div>{{ timeToString(item.date_vote) }}</div>
                                                        </div>
                                                    </template>
                                                    <v-card class="pa-2 menu_text" max-width="800px" v-if="item.guest_item"> 
                                                        <div>Телефон: <b v-if="item.guest_phone !== null">{{ item.guest_phone }}</b> <b v-else>Номер не указан</b></div>
                                                        <div>Имя: <b v-if="item.guest_name !== null">{{ item.guest_name }}</b> <b v-else>Имя не указано</b></div>
                                                        <div>Первый визит: <b>{{ timeToString(item.guest_item.date_create) }}</b></div>
                                                        <div>Последний визит: <b>{{ timeToString(item.guest_item.date_visit) }}</b></div>
                                                        <v-divider class="mt-1 mb-1"></v-divider>
                                                        <div v-if="($store.state.userIsPoint && $store.state.user.point_id) || filter_point">
                                                            <div >Заказы точки:     <b>{{  item.guest_item.count }}</b></div>
                                                            <div>Чек у точки:   <b>{{  item.guest_item.cost }}</b></div>
                                                            <div>Заказы у других: <b>{{ item.guest_item.other_count }}</b></div>
                                                            <div>Чек у других: <b>{{ item.guest_item.other_cost }} </b></div>
                                                        </div>
                                                        <div>Заказы всего: <b>{{ item.guest_item.order_count }}</b></div>
                                                        <div v-if="!(($store.state.userIsPoint && $store.state.user.point_id) || filter_point)" >Чек всего: <b>{{ item.guest_item.pay_cost }} </b></div>
                                                        <div v-if="!$store.state.userIsPoint">Точки: <b>{{ item.guest_item.points }}</b></div>
                                                    </v-card>
                                                </v-menu> 
                                            </v-col>
                                            <v-col cols="8" align-self="start">
                                                <v-rating
                                                    background-color="grey lighten-1"
                                                    color="warning"
                                                    empty-icon="mdi-star-outline"
                                                    full-icon="mdi-star"
                                                    half-icon="mdi-star-half-full"
                                                    length="5"
                                                    size="14"
                                                    :value="item.value"
                                                    dense
                                                    readonly
                                                ></v-rating>
                                                <div> <b>{{ pointNameGet(item.point_id) }}</b> </div>
                                                <div v-if="item.review_text">
                                                    <br>
                                                    {{ item.review_text }}
                                                </div>
                                                <div :id="`${'audio'+item.id}`" style="display: flex; flex-wrap: wrap;" v-if="item.mediaFiles.isAudio">
                                                    <audio controls :src="item.mediaFiles.audio[0].url"></audio>
                                                </div>
                                                <v-slide-group
                                                    class="pa-4"
                                                    center-active
                                                    show-arrows
                                                    style="padding: 5px !important;"
                                                    v-if="item.mediaFiles.isMedia"
                                                >
                                                    <v-slide-item
                                                    v-for="elem in item.mediaFiles.media"
                                                    :key="elem.url"
                                                    style="margin: 5px !important;"
                                                    >
                                                        <v-card
                                                            class="ma-4 elevation-1"
                                                            height="100"
                                                            width="100"
                                                            @click="elem.type=='img'?clickMedia(elem):''"
                                                        >
                                                            <video v-if="elem.type&&elem.type=='video'" :src="elem.url" max-width="100%" height="100%" controls></video>
                                                            <v-img v-if="elem.type&&elem.type=='img'" :src="elem.url" max-width="100%" height="100%"></v-img>
                                                        </v-card>
                                                    </v-slide-item>
                                                </v-slide-group>
                                                <br>
                                                <v-menu  bottom color="rgba(255,255,255, 0)" open-on-hover :close-on-content-click="false" offset-y close-delay="100" open-delay="200" >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span 
                                                            class="tooltip-text" 
                                                            v-bind="attrs" 
                                                            v-on="on"
                                                        >
                                                                <b>Заказ:</b> {{ item.order_name }}
                                                        </span>
                                                    </template>
                                                    <v-card class="pa-2 menu_text" max-width="800px"> 
                                                        <div>Название: <b>{{ item.order_name }}    </b>
                                                            <v-avatar size="20">
                                                                <img alt="source" :src="iconSource(item)" />
                                                            </v-avatar>
                                                        </div>
                                                        <div>Точка: <b>{{ pointNameGet(item.point_id) }}</b></div>
                                                        <div>Курьер: <b>{{ item.courier_name }}</b></div>
                                                        <v-divider class="mt-1 mb-1"></v-divider>
                                                        <div>Забран: <b>{{ timeToString(item.date_take) }} </b>
                                                            <v-chip v-if="item.date_take" class="px-1 mb-1" :color="timeColorHtml(item.date_take, item.date_ready)" x-small label text-color="white">
                                                                {{ timeError(item.date_take, item.date_ready) }}
                                                            </v-chip>
                                                        </div>
                                                        <div>Доставлен: <b>{{ timeToString(item.date_complete) }}</b>
                                                            <v-chip v-if="item.date_complete" class="px-1 mb-1" :color="timeColorHtml(item.date_complete, item.date_delivery)" x-small label text-color="white">
                                                                {{ timeError(item.date_complete, item.date_delivery) }}
                                                            </v-chip>
                                                        </div>
                                                    </v-card>
                                                </v-menu>
                                                <div> <b>Курьер:</b> {{ item.courier_name }} </div>
                                                <div> <span class="mr-2"><b>Время доставки: {{ getStringToDate(item.date_complete, 'DD MMM HH:mm')  }}</b></span>
                                                    <v-chip 
                                                        class="px-1" 
                                                        :color="timeColorHtml(item.date_complete, item.date_delivery)" 
                                                        x-small 
                                                        label 
                                                        text-color="white"
                                                    >
                                                        {{ timeError(new Date(item.date_complete), new Date(item.date_delivery)) }}
                                                    </v-chip>
                                                </div>
                                            </v-col>
                                            <v-col cols="2">
                                                <img
                                                    v-if="item.platform || item.value < 5"
                                                    class="mt-1"
                                                    height="24"
                                                    alt="platform"
                                                    :src="iconPlatformSource(item.platform)"
                                                >
                                                <span v-if="!item.platform && item.value >= 5">
                                                    Без перехода
                                                </span>
                                            </v-col> 
                                            <v-col cols="12">
                                                <span v-if="item.text_made" style="color: DarkSlateBlue;padding: 0 10px;">{{item.text_made}}</span>
                                                <span v-else style="color: DarkSlateBlue;padding: 0 10px;"> <i> Отзыв не был отработан</i></span>
                                                <v-btn icon small style="margin: 0 0 5px 0;" @click="clickUpdateTextMade(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                                            </v-col>
                                            <v-col cols="10">
                                                <div>
                                                    <v-chip-group column >
                                                        <v-chip v-for="tag in item.hashtags" :key="tag" outlined @click="clickHashtag(item, tag)" small> 
                                                            {{tag}}
                                                        </v-chip>
                                                        <v-chip outlined @click="clickAddHashtag(item)" small> 
                                                            <v-icon color="green">mdi-plus</v-icon>
                                                        </v-chip>
                                                    </v-chip-group>
                                                </div>
                                            </v-col>
                                            <v-col cols="2">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span class="tooltip-text" v-bind="attrs" v-on="on" @click="onClickReview(item)">
                                                            <v-btn v-if="respondTooltip(item).includes('без ответа')" plain rounde text small :style="{color: respondColorHtml(item)}"> Ответить</v-btn>
                                                            <v-icon v-else :style="{color: respondColorHtml(item)}">{{ respondIcon(item) }}</v-icon>
                                                        </span>
                                                    </template>
                                                    <span style="white-space: pre;">{{ respondTooltip(item) + textClick(respondTooltip(item))}}</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="10" style="text-align: center; color: rgba(0, 0, 0, 0.6); font-family: 'Roboto', sans-serif;" v-if="countReviews >= reviews.length && reviews.length">
                                <span>Больше отзывов нет</span>
                            </v-col>
                            <v-col cols="10" style="text-align: center;" v-if="countReviews < reviews.length">
                                <v-progress-circular
                                indeterminate
                                color="indigo darken-6"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogInfoChange" :width="dialogTextMade?'400':'350'" @click:outside="closeDialogHashtag">
            <v-card>
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        plain
                        small
                        @click="closeDialogHashtag"
                    ><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>

                <v-card-text>
                    <v-textarea
                        id="textareaDialog"
                        :value="nameInfoText"
                        :label="labelInfoText"
                        auto-grow
                        outlined
                        rows="3"
                        :row-height="dialogTextMade?'20':'10'"
                        shaped
                        @click="alertHashtag=false"
                    ></v-textarea>
                </v-card-text>
                <v-alert v-model="alertHashtag" dismissible dense style="position: relative; margin: 6px;" :type="typeAlert" class="alert elevation-3"> Заполните поле  </v-alert>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="success" dark small text @click="updateNameInfoText">Сохранить</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" v-if="!dialogTextMade" dark small text @click="clickDelete">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogMedia" :width="currentMedia?currentMedia.width:'720'" :height="currentMedia?currentMedia.height:'570'" @click:outside="dialogMedia = false"> 
            <v-card width="auto" height="auto">
                <v-layout align-end >
                    <v-menu
                        absolute
                        offset-y
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            icon
                            plain
                            small
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                        </template>
                        <v-list dense style="padding: 0;" rounded outlined>
                            <v-list-item @click="downloadMedia(currentMedia.url, currentMedia.path)">
                            <v-list-item-title>Скачать</v-list-item-title>
                            <v-icon>mdi-content-save-outline</v-icon>
                            </v-list-item>
                        </v-list>
                        </v-menu>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        plain
                        small
                        style="margin-right: 5px;"
                        @click="dialogMedia = false"
                    ><v-icon>mdi-close</v-icon></v-btn>
                </v-layout>
                <video v-if="currentMedia&&currentMedia.type=='video'" :src="currentMedia.url" controls></video>
                <v-img v-if="currentMedia&&currentMedia.type=='img'" :src="currentMedia.url" ></v-img>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" width="250" @click:outside="dialogDelete = false; dialogInfoChange = true">
            <v-card>
                <v-card-text class="text-center" style="display: inline-block; margin: 25px 0 0 0;">Вы уверены что хотите удалить?</v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                    <v-btn width="50%" color="success" dark small text @click="deleteHashtags">Да</v-btn>
                    <v-btn width="50%" color="error" dark small text @click="dialogDelete = false; dialogInfoChange = true">Нет</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogReview"
          width="720"
          @click:outside="alert = false"
        >

          <v-card>
            <v-card-title class="headline grey lighten-2">
              {{ `Отзыв гостя ${currentItem.guest_name ? currentItem.guest_name : currentItem.guest_phone}` }}
            </v-card-title>

            <v-card-text>

                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="10">
                                <div class="d-inline-flex">
                                    <span class="mr-5 my-1 align-center">Оценка</span>
                                    <v-rating
                                        background-color="grey lighten-1"
                                        color="warning"
                                        empty-icon="mdi-star-outline"
                                        full-icon="mdi-star"
                                        half-icon="mdi-star-half-full"
                                        length="5"
                                        size="24"
                                        :value="selectedItem.value"
                                        readonly
                                        dense
                                    ></v-rating>
                                </div>
                            </v-col>
                            <v-col cols="10" v-if="!selectedItem.review_text">
                                <span class="mr-5 my-1 align-center">Текст отзыва гость не написал</span>
                            </v-col>
                            <v-col cols="10" v-if="selectedItem.review_text">
                                <v-textarea
                                  v-model="selectedItem.review_text"
                                  readonly
                                  label="Текст отзыва"
                                  auto-grow
                                  outlined
                                  rows="3"
                                  row-height="25"
                                  shaped
                                  :messages="selectedItem.status >= config.review.status.voted ? 'Время отзыва: ' + timeToString(selectedItem.date_vote) : ''"
                                ></v-textarea>
                            </v-col>
                            <v-col cols="10">
                                <v-textarea
                                  v-model="respondText"
                                  :label="selectedItem.status == config.review.status.responded ? 'Ответ на отзыв' : 'Отправить ответ'"
                                  auto-grow
                                  outlined
                                  rows="3"
                                  row-height="25"
                                  shaped
                                  :readonly="selectedItem.status == config.review.status.responded"
                                  :messages="selectedItem.status == config.review.status.responded ? 'Время ответа: ' + timeToString(selectedItem.date_respond) : ''"
                                ></v-textarea>
                                <!-- <span v-if="selectedItem.status == config.review.status.responded">Дата ответа: <b>{{ timeToString(selectedItem.date_respond) }}</b></span> -->
                                <v-btn v-if="selectedItem.status != config.review.status.responded" color="success" dark @click="onSendRespond">Отправить</v-btn>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-form>

            </v-card-text>
            <v-alert style="position: relative; margin-bottom: 10px;" :type="typeAlert" class="alert elevation-3" v-model="alert" :dismissible="true">{{ this.alertText }}</v-alert>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="mx-2" color="primary" text @click="dialogReview = false; alert = false">Закрыть</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        

        <span class="pb-16 d-block"/>

    </div>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'

    moment.locale('ru')

    import DateTime from '../../mixins/DateTime.js'
    import Status from '../../mixins/Status.js'

    export default {
        mixins: [DateTime, Status],
        data() {
            return {
                dates: [],
                calendar: 0,
                menu_calendar: false,
                loading: false,
                search: '',
                loyalties_count: 0,
                reviewsStars: 0,
                reviews: [],
                points: [],
                couriers: [],
                filter_point: 0,
                filter_points: [],
                filter_courier: 0,
                filter_couriers: [],
                headers: [],
                orderStatuses: [],
                timer: null,
                
                dialogReview: false,
                dialogHeader: '',
                dialogContent: '',
                respondText: '',
                config,
                currentItem: {},
                selectedItem: {},
                timerAddressUpdate: null,
                addressUpdateFlag: false,
                changeFlag: false,
                alert: false,
                alertText: '',
                typeAlert: 'warning',
                status_codes: ['103', '104', '105', '106', '107', '108', '150', '200', '201', '202', '203', '204', '205', '206', '207', '208', '209', '213', '220', '230', '231', '232', '233', '300', '301', '302', '500'],
                d0: null,
                d1: null,
                d2: null,
                userIsPoint: false,
                guestNameClick: false,
                guestCreateClick: false,

                tabularView: false,
                reviewsAll: [],
                hashtags: [],
                quick_filters: [],
                quick_filter: '',
                dialogInfoChange: false,
                dialogDelete: false,
                indexHashtag: 0,
                nameInfoText: '',
                alertHashtag: false,
                dialogTextMade: false,
                labelInfoText: '',

                dialogMedia: false,
                currentMedia: null,

                countReviews: 6,
            }
        },
        components: {},
        mounted() {
            console.log('reviews start!')
            this.userIsPoint = this.$store.state.user.role != config.user.role.operator
            if (this.userIsPoint) {
                this.headers = [
                    {
                        text: 'Гость',
                        value: 'guest',
                    },
                    {
                        text: 'Оценка',
                        value: 'value',
                    },
                    {
                        text: 'Отзовик',
                        value: 'platform',
                    },
                    {
                        text: 'Ответ',
                        value: 'respond',
                    },
                    {
                        text: 'Текст отзыва',
                        sortable: false,
                        value: 'review_text',
                    },
                    {
                        text: 'Время отзыва',
                        sortable: false,
                        value: 'date_vote',
                    },
                    // {
                    //     text: 'Точка',
                    //     value: 'point_name',
                    // },
                    {
                        text: 'Курьер',
                        value: 'courier_name',
                    },
                    {
                        text: 'Заказ',
                        sortable: false,
                        value: 'order_name',
                    },
                    {
                        text: 'Заказ доставлен',
                        sortable: false,
                        value: 'date_complete',
                    },
                ]
            } else {
                this.headers = [
                    {
                        text: 'Гость',
                        value: 'guest',
                    },
                    {
                        text: 'Оценка',
                        value: 'value',
                    },
                    {
                        text: 'Отзовик',
                        value: 'platform',
                    },
                    {
                        text: 'Ответ',
                        value: 'respond',
                    },
                    {
                        text: 'Текст отзыва',
                        sortable: false,
                        value: 'review_text',
                    },
                    {
                        text: 'Время отзыва',
                        sortable: false,
                        value: 'date_vote',
                    },
                    {
                        text: 'Точка',
                        value: 'point_name',
                    },
                    {
                        text: 'Курьер',
                        value: 'courier_name',
                    },
                    {
                        text: 'Заказ',
                        sortable: false,
                        value: 'order_name',
                    },
                    {
                        text: 'Заказ доставлен',
                        sortable: false,
                        value: 'date_complete',
                    },
                ]
            }
            this.dates[0] = moment().format('YYYY-MM-DD')
            this.dates[1] = moment().format('YYYY-MM-DD')
            this.d0 = this.getDate1String(-4)
            this.d1 = this.getDate1String()
            this.d2 = this.getDate2String()
            this.quick_filters = ['<Все>', 'Положительные отзывы', 'Негативные отзывы', 'Отзывы с текстом', 'Отзывы без текста', 'Отзывы с медиа', 'Отработанные отзывы']
            this.quick_filter = this.quick_filters[0]
            this.updateData()
            this.getNextReview()
        },

        beforeDestroy() {},

        destroyed() {
            return this.timer ? clearInterval(this.timer) : false
        },
        watch: {},
        computed: {
            dateRangeText() {
                return this.dates.join(' ~ ')
            },
            reviewsBeforeScroll() {
                return this.reviews.slice(0, this.countReviews - 1)
            }
        },
        methods: {
            onClickGuest(order) {
                if (order.guest_id) {
                    this.guestNameClick = true
                    const name = order.guest_name ? order.guest_name + ' ' : ''
                    this.$notify.toast('Гость: ' + name + order.guest_phone, {color:'info', timeout: 4000})
                    setTimeout(() => {
                        this.guestNameClick = false
                    }, 100)
                }
            },
            getGuestPhoneName(order) {
                let guest_name = ''
                if (order.guest_id && order.guest_phone) {
                    guest_name += order.guest_phone
                    if (order.guest_name) guest_name += ' - ' + order.guest_name
                } else {
                    if (order.guest) {
                        guest_name = this.getGuestStr(order.guest).substr(0, 24)
                    }
                }
                return guest_name
            },

            onSendRespond() {
                let subscriber
                let re = /\+?[78][-(]?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}/
                if (!re.test(this.selectedItem.guest)) {
                    subscriber = ''
                    this.typeAlert = 'warning'
                    this.alert = true
                    this.alertText = 'Не могу отправить сообщение. Некорректный номер у гостя.'
                    return
                } else {
                    subscriber = re.exec(this.selectedItem.guest)[0]
                }


                this.$store
                    .dispatch('sms_send', {
                        point_id: this.selectedItem.point_id,
                        phone: subscriber,
                        text: this.respondText,
                    })
                    .then(res => {
                        if (res.data.success) {
                            console.log('sms_send ok', res.data)
                            this.showAlert('warning', res.data.description)
                            // this.showAlert('warning', 'Отправлено')
                            this.smsStatusCheck(res.data.message_id)
                            this.selectedItem.respond_text = this.respondText
                            this.selectedItem.date_respond = new Date()
                            this.selectedItem.status = config.review.status.responded
                            this.currentItem.respond_text = this.selectedItem.respond_text
                            this.currentItem.date_respond = this.selectedItem.date_respond
                            this.currentItem.status = this.selectedItem.status
                            this.saveReview(this.currentItem)
                        } else {
                            this.showAlert('error', 'Сообщение не отправлено. ' + res.data.description)
                        }
                    })
                    .catch(err => {
                        this.showAlert('error', 'Сообщение не отправлено. ')
                        console.log(err)
                    })
            },
            smsStatusCheck(message_id) {
                this.timer = setTimeout(() => {
                    this.$store
                        .dispatch('sms_status', {
                            point_id: this.selectedItem.point_id,
                            message_id,
                        })
                        .then(res => {
                            if (res.data.success) {
                                console.log('sms_status ok', res.data)
                                if (res.data.msg != 'ok') {
                                    // this.$notify.toast(res.data.description, {color:'warning', timeout: 4000})
                                    this.showAlert('warning', res.data.description)
                                    this.smsStatusCheck(message_id)
                                } else {
                                    // this.$notify.toast(res.data.description, {color:'success', timeout: 4000})
                                    this.showAlert('success', res.data.description)
                                }
                            } else {
                                console.log('sms_status fail')
                            }

                        })
                        .catch(err => {
                            console.log(err)
                        })
                }, 2000)
            },
            showAlert(type, text) {
                this.typeAlert = type
                this.alert = true
                this.alertText = text
            },
            saveReview(review) {
                console.log('review_set', review)
                this.$store
                    .dispatch('review_set', review)
                    .then(res => {
                        if (res.data.success) {
                            console.log('review_set success')
                        } else {
                            console.log('review_set not success')
                        }
                    })
                    .catch(err => {
                        console.log('review_set error', err)
                    })
            },
            onClickReview(item) {
                if (this.guestNameClick || this.dialogInfoChange) return
                this.dialogHeader = `Отзыв гостя ${item.guest_name ? item.guest_name : item.guest_phone}`
                this.currentItem = item
                this.selectedItem = JSON.parse(JSON.stringify(item))
                this.respondText = this.selectedItem.respond_text
                this.dialogReview = true
            },
            onClickChangeDate(arg) {
                this.d0 = this.getDate1StringFromDate(this.dates[0])
                this.d1 = this.getDate1StringFromDate(this.dates[0])
                this.d2 = this.getDate2StringFromDate(this.dates[1])
                this.updateData()
            },
            onClickDownloadData() {
                let point
                let operator
                if (this.userIsPoint) {
                    point = this.$store.state.user.point_id
                    operator = this.$store.state.user.operator_tid
                } else {
                    point = this.filter_point
                    operator = this.$store.state.user.tid
                }
                let url = `${ config.api.delivery }reviews_export?tz=${ this.$store.state.settings.tz }&tz_string=${this.$store.state.settings.tz_string}&operator_tid=${ operator }&date1=${ this.d1 }&date2=${ this.d2 }&point=${ point }&courier=${ this.filter_courier }`
                window.location.href = url
            },
            onClickToday() {
                this.dates[0] = moment().format('YYYY-MM-DD')
                this.dates[1] = moment().format('YYYY-MM-DD')

                this.d0 = this.getDate1String(-14)
                this.d1 = this.getDate1String()
                this.d2 = this.getDate2String()
                this.updateData()
            },
            onClickYesterday() {
                this.dates[0] = moment().subtract(1, 'd').format('YYYY-MM-DD')
                this.dates[1] = moment().subtract(1, 'd').format('YYYY-MM-DD')

                this.d0 = this.getDate1String(-15)
                this.d1 = this.getDate1String(-1)
                this.d2 = this.getDate2String(-1)
                this.updateData()
            },
            onClickMonth() {
                this.dates[0] = moment().subtract(30, 'd').format('YYYY-MM-DD')
                this.dates[1] = moment().format('YYYY-MM-DD')

                this.d0 = this.getDate1String(-44)
                this.d1 = this.getDate1String(-30)
                this.d2 = this.getDate2String()
                this.updateData()
            },
            onClickUpdate() {
                this.updateData()
            },
            getGuestStr(guest) {
                let splittedStr = guest.split('Телефон:')
                return splittedStr.length > 1 ? splittedStr[0] + ' ' + splittedStr[1].substr(0, 15) : guest
            },
            getTdCatted(str) {
                return '<td>Hello</td>'
            },
            pointNameGet: function(point_id) {
                let point_name = '--'
                const point = this.points.find(point => point.id == point_id)
                if (point) {
                    point_name = point.name
                }
                return point_name
            },
            getRandomInt() {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5
            },
            updateData() {
                this.loading = true
                let point_id
                if (this.userIsPoint) {
                    point_id = this.$store.state.user.point_id
                } else {
                    point_id = this.filter_point
                }
                this.$store
                    .dispatch('reviews_date', {
                        date1: this.d1,
                        date2: this.d2,
                        point_id,
                        courier_tid: this.filter_courier,
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.loyalties_count = res.data.reviews.length
                            this.reviews = res.data.reviews.filter(l => l.status > 1).reverse()
                            let review_stars = 0
                            for (let review of this.reviews) {
                                review_stars += +review.value
                                review.hashtags = review.hashtags?review.hashtags.replace('food', '#Еда').replace('delivery', '#Доставка'):review.hashtags
                                review.hashtags = JSON.parse(review.hashtags)
                                review.mediaFiles = {audio: [], media: [], isAudio:false, isMedia:false, checkMedia: false}
                            }
                            this.reviewsStars = review_stars / this.reviews.length
                            console.log('reviews', this.reviews)
                            this.reviewsAll = this.reviews
                            this.filterTags()
                            this.loading = false
                        } else {
                            this.reviewsStars = 0
                        }

                        this.$store
                            .dispatch('points_get')
                            .then(res => {
                                if (res.data.success) {
                                    this.points = res.data.points
                                    console.log('points', this.points)
                                }
                                this.filter_points = this.points.map(p => ({ id: p.id, name: p.name }))
                                // this.filter_points = this.reviews.map(review => ({ id: review.point_id, name: this.pointNameGet(review.point_id) }))
                                this.filter_points.splice(0, 0, { id: 0, name: '< Все >' })
                                this.$store
                                    .dispatch('couriers_get')
                                    .then(res => {
                                        if (res.data.success) {
                                            this.couriers = res.data.couriers
                                            console.log('couriers', this.couriers)
                                        }
                                        this.filter_couriers = this.couriers.map(c => ({ tid: c.tid, name: c.full_name }))
                                        this.filter_couriers.splice(0, 0, { tid: 0, name: '< Все >' })
                                        console.log('filter_couriers', this.filter_couriers)
                                        this.loading = false
                                    })
                                    .catch(err => {
                                        this.loading = false
                                        console.log(err)
                                    })
                            })
                            .catch(err => {
                                this.loading = false
                                console.log(err)
                            })

                        this.$store
                            .dispatch('guests_get', {
                                date1: this.d0,
                                date2: this.d2,
                                point_id,
                            })
                            .then(res => {
                                if (res.data.success) {
                                    const guests = res.data.guests
                                    // console.log('guests', guests)
                                    for (let review of this.reviewsAll) {
                                        if (review.guest_id) {
                                            var review_c = this.reviews.find(r => r.id == review.id)
                                            const guest = guests.find(g => g.id == review.guest_id)
                                            if (guest) {
                                                console.log('guest.name', guest.name)
                                                review.guest_name = guest.name
                                                review.guest_phone = guest.phone

                                                if (point_id){
                                                    guest.date_create = guest.options.points[point_id].create
                                                    guest.date_visit = guest.options.points[point_id].visit
                                                    guest.count = guest.options.points[point_id].count
                                                    guest.cost = guest.options.points[point_id].cost
                                                    if (guest.cost < 99 * guest.count) {
                                                        guest.cost = 500 * guest.count
                                                    }
                                                    guest.other_count = guest.order_count - guest.options.points[point_id].count
                                                    guest.other_cost = guest.pay_cost - guest.options.points[point_id].cost
                                                } 
                                                
                                                if (guest.other_cost < 99 * guest.other_count) {
                                                    guest.other_cost = 500 * guest.other_count
                                                } 
                                                if (!this.$store.state.userIsPoint){
                                                    if (guest.point_ids.length) {
                                                        guest.points = ''
                                                        for (const point_id of guest.point_ids) {
                                                            const point = this.points.find(p => p.id == point_id) 
                                                            if (point) {
                                                                guest.points += point.name + ', '
                                                            } else {
                                                                guest.points += 'скрыто, '
                                                            }
                                                        }
                                                        guest.points = guest.points.substring(0, guest.points.length - 2)
                                                    }
                                                }

                                                review.guest_item = guest
                                                if (review_c) review_c = review
                                            } else {
                                                console.log('review', review)
                                            }
                                        }
                                        this.loading = false
                                    }
                                }
                                this.loading = false
                            })
                            .catch(err => {
                                console.log('2 error', err.name, err.message)
                                this.loading = false
                            })
                    })
                    .catch(err => {
                        console.log(err)
                        this.loading = false
                    })
            },
            closeCalendar() {
                this.menu_calendar = false
            },
            filterTags(){
                var selectedHashtags = this.hashtags
                var hashtags = []
                for (let item of this.reviews){
                    if (typeof item.hashtags === "string") item.hashtags = JSON.parse(item.hashtags)
                    if (item.hashtags) {
                        hashtags = hashtags.concat(item.hashtags)
                    }
                }
                hashtags = new Set(hashtags)
                this.hashtags = []
                for (let tag of hashtags){
                    let isSelected = (selectedHashtags && selectedHashtags.length != 0) ? selectedHashtags.find(tags => tags.name === tag): false
                    isSelected = isSelected ? isSelected.isSelected : false
                    this.hashtags.push({
                        name: tag,
                        isSelected: isSelected ? isSelected : false,
                    })
                }
                this.filters()
            },
             filters(hashtag){
                if (hashtag != undefined){
                    hashtag = this.hashtags.find(tag => tag === hashtag)
                    hashtag.isSelected = !hashtag.isSelected
                }
                console.log(this.hashtags)
                let count = 0
                for (let tag of this.hashtags) if (!tag.isSelected) count++
                
                this.reviews = this.reviewsAll
                if (count != this.hashtags.length) {
                    for (let tag of this.hashtags){
                        this.reviews = this.reviews.filter(item => (tag.isSelected ? (item.hashtags ? item.hashtags.indexOf(tag.name)!=-1 : false) : true))     
                    }
                }
                this.reviews = this.reviews.filter(item => ((this.quick_filter==="<Все>"?true:false)||(this.quick_filter==="Положительные отзывы"?(item.value===5):false)
                    ||(this.quick_filter==="Негативные отзывы"?(item.value!=5):false)||(this.quick_filter==="Отзывы с текстом"?(item.review_text):false)
                    ||(this.quick_filter==="Отзывы без текста"?(!item.review_text):false) || (this.quick_filter==="Отзывы с медиа"?(item.media.length):false)
                    ||(this.quick_filter==="Отработанные отзывы"?(item.respond_text):false))) 
                if (!this.tabularView)
                    this.reviews = this.reviews.filter(item => ((item.courier_name?item.courier_name.search(new RegExp(this.search, "i"))!=-1:false) 
                        || (item.guest_name?item.guest_name.search(new RegExp(this.search, "i"))!=-1:false) || (item.guest_phone?item.guest_phone.search(new RegExp(this.search, "i"))!=-1:false) 
                        || (item.order_name?item.order_name.search(new RegExp(this.search, "i"))!=-1:false) || (item.review_text?item.review_text.search(new RegExp(this.search, "i"))!=-1:false)))
                console.log(this.reviews)
                var review_stars = 0
                for (let review of this.reviews) {
                    review_stars += +review.value
                }
                this.reviewsStars = review_stars / this.reviews.length
                this.countReviews = 6
                this.getMedia(0, this.countReviews-1)
            },
            saveReviewInfoText(review){
                review.updateInfoText = true
                this.$store
                    .dispatch('review_set', review)
                    .then(res => {
                        review.updateInfoText = false
                        this.filterTags()
                    })
                    .catch(err => {
                        console.log(err)
                        review.updateInfoText = false
                    }) 
            },
            updateNameInfoText(){
                this.nameInfoText = document.querySelector('#textareaDialog').value
                if (this.dialogTextMade) {
                    if (this.nameInfoText === '') this.currentItem.text_made = null
                    else this.currentItem.text_made = this.nameInfoText
                    this.updateInfoText()
                    return
                }
                if (this.nameInfoText === '')  this.alertHashtag = true
                else{
                    this.currentItem.hashtags[this.indexHashtag] = this.nameInfoText
                    this.updateInfoText()
                }
            },
            updateInfoText(){
                this.saveReviewInfoText(this.currentItem)
                this.dialogInfoChange = false
                this.dialogTextMade = false
                this.alertHashtag = false
                this.nameInfoText = ''
            },
            deleteHashtags(){
                this.alertHashtag = false
                this.dialogDelete = false
                this.dialogInfoChange = false
                if (this.currentItem.hashtags[this.indexHashtag]) {
                    this.currentItem.hashtags.splice(this.indexHashtag, 1)
                    this.updateInfoText()
                }
            },
            clickHashtag(item, tag){
                this.currentItem = item
                this.indexHashtag = item.hashtags.indexOf(tag)
                this.nameInfoText = tag
                this.labelInfoText = "Хештег"
                this.dialogInfoChange = true
                this.dialogReview = false
            },
            clickAddHashtag(item){
                this.currentItem = item
                if (!this.currentItem.hashtags) this.currentItem.hashtags = []
                this.indexHashtag = this.currentItem.hashtags.length != 0 ? this.currentItem.hashtags.length : 0
                this.nameInfoText = ''
                this.labelInfoText = "Хештег"
                this.dialogInfoChange = true
                this.dialogReview = false
            },
            closeDialogHashtag(){
                this.dialogInfoChange = false
                this.dialogTextMade = false
                this.alertHashtag = false
                this.nameInfoText = ''
            },
            clickDelete(){
                if (this.currentItem.hashtags[this.indexHashtag]) this.dialogDelete = true
                this.dialogInfoChange=false
            },
            clickUpdateTextMade(item){
                this.nameInfoText = item.text_made ? item.text_made : ''
                this.currentItem = item
                this.labelInfoText = "Как был отработан данный отзыв?"
                this.dialogInfoChange = true
                this.dialogTextMade = true
            },
            getMedia(start, end){
                for (let item of this.reviews.slice(start, end)){
                    if (!item.mediaFiles.checkMedia && item.media.length){
                        item.mediaFiles.checkMedia = true
                        for(let file of item.media){
                            this.$store
                            .dispatch('get_media_file', file.path)
                            .then(res => {
                                //console.log(res.data) // что-то с ним сделать
                                if (file.type_media === 'audio') {
                                    item.mediaFiles.audio.push({
                                        path: file.path,
                                        url: res.request.responseURL,
                                    })
                                    item.mediaFiles.isAudio = true
                                } else {
                                    item.mediaFiles.media.push({
                                        path: file.path,
                                        url: res.request.responseURL,
                                        type: file.type_media
                                    }) 
                                    item.mediaFiles.isMedia = true
                                }
                                this.countReviews++
                                console.log("media item", res.request.responseURL)
                                this.countReviews--
                            })
                            .catch(err => {
                                console.log(err)
                                let index = item.media.indexOf(file)
                                if (index > -1) {
                                    item.media.splice(index, 1)
                                    this.filters()
                                }
                            }) 
                        }        
                    }
                            
                }
            },
            textClick(text){
                return text.includes('без ответа')?"\nНажмите чтобы ответить":""
            },
            clickMedia(elem){
                var img =  document.createElement('img') 
                var width 
                var height
                img.src=elem.url
                if (parseFloat(img.width) > parseFloat(img.height)){
                    width = 720
                    height = ((parseFloat(img.height) + 30) * 720)/parseFloat(img.width)
                } else {
                    height = parseFloat(document.documentElement.clientHeight) - 50
                    width = (height * parseFloat(img.width))/(parseFloat(img.height) + 30)
                }
                this.currentMedia = {
                    url: elem.url,
                    path: elem.path,
                    type: elem.type,
                    width: width,
                    height: height    
                }
                this.dialogMedia = true
            },
            clickTabularView(){
                this.tabularView = !this.tabularView
                this.filters() 
            },
            getNextReview() {
                window.onscroll = () => {
                    if (document.documentElement.scrollTop + window.innerHeight >= document.documentElement.scrollHeight - 850
                        && (this.countReviews < this.reviews.length)) {
                        this.getMedia(this.countReviews, this.countReviews+4)
                        this.countReviews += 5
                    }
                }
            },
            downloadMedia(url, path){
                var name = path.split('/')
                name = name[name.length - 1]
                var link = document.createElement("a")
                link.setAttribute("href", url)
                link.setAttribute("download", name)
                link.setAttribute("target", "blank")
                link.click()
                link = null
            }
        },
    }
</script>

<style lang="stylus">
    .reviews
        height 100%
        padding-top 10px !important

        // .text-nowrap
        //     word-wrap normal
        //     overflow hidden
        //     white-space nowrap

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    padding 0 8px
                    white-space nowrap

            .v-data-table__wrapper > table > tbody > tr
                cursor pointer
                td
                    white-space nowrap
                    padding 0 8px
                    font-size 0.75rem
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px
                    td.tooltip-text
                        padding-left 0
                    td
                        padding-left 0

        .v-tooltip__content
            background #222
            color #fff
            border-radius 6px
            padding 5px 10px 4px
            &:hover
                cursor pointer

        .v-chip
            cursor pointer
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px
                // cursor pointer
                padding 0 6px

        .v-chip.v-size--x-small
            height 18px

        b
            font-weight 500

        .guest-error
            color #F44336

        .guest-selectable
            font-weight 400
            color #0D47A1
            cursor pointer
            &:hover
                text-decoration: underline

    .v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot textarea
        margin 10px
        padding 0

    .menu_text 
        font-size: 13px
    audio 
        width 80%
        height 35px
        margin-top 5px
    .v-slide-group__next, .v-slide-group__prev
        min-width 20px
    .v-dialog > .v-card > .v-card__text 
        padding 0 24px 0px
    .v-dialog 
        overflow hidden
    audio::-webkit-media-controls-timeline-container
        color gray
    audio::-webkit-media-controls-timeline
        color gray
</style>
